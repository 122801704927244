<template>
  <div v-loading="loading" v-if="canSeeNotes">
    <trieste-card title="Notes">
      <template v-slot:header-right>
        <el-button type="success" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('note.create')" size="small" @click="openCreateNoteDialog">Create</el-button>
      </template>
      <div class="p-4 flex flex-row justify-between items-center" v-if="note_pagination.total > note_pagination.rpp">
        <small>Showing page {{note_pagination.page}} of {{note_pagination.last_page}}. Total results {{note_pagination.total}}</small>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="note_pagination.total"
          :page-size.sync="note_pagination.rpp"
          :current-page="note_pagination.page"
          @current-change="notePaginationChange"
        >
        </el-pagination>
      </div>
      <div>
        <div v-if="!notesAndTasks.length" class="text-sm text-gray-500 p-4">
          There are currently no notes/tasks
        </div>
        <div v-else v-for="(note, i) in notesAndTasks" :key="'note_a_'+i">
          <div class="p-4 py-2">
            <div class="rounded border border-gray-200 p-4 bg-yellow-400">
              <div class="mr-2 flex flex-row items-center text-xs" v-if="note.urgent">
                <span class="w-2 h-2 inline-block rounded-full bg-red-600 align-middle mr-1"></span>
                <span class="font-medium text-red-600 align-middle">Urgent</span>
              </div>
              <div :class="{'line-through': note.completed}">
              <span class="font-bold text-xs text-black"></span>
              <div class="text-gray-700 text-sm inline-block break-words w-full" v-html="note.preview" v-if="note.preview && !note.show_full" :class="{'line-through': note.completed}"></div>
              <div class="text-gray-700 text-sm inline-block break-words w-full" v-html="formatNote(note.body || note.preview)" v-else :class="{'line-through': note.completed}"></div>
              </div>
              <div class="text-right">
                 <span class="btn text-xs text-blue-800 font-bold rounded border border-blue-800 p-1 px-2 ml-1 cursor-pointer" v-if="note.preview" @click="note.show_full = !note.show_full">
                  {{ note.show_full ? 'Hide' : 'Show all' }}
                </span>
              </div>
              <div class="mt-1">
                <div :class="{'line-through': note.completed}">
                <span class="text-gray-600 text-xs block">{{ note.note_type  }}</span>
                <span class="text-gray-700 text-xs block"><span class="text-gray-600">Creator: </span> {{ note.owner ? `${note.owner.firstname} ${note.owner.lastname}` : 'System'  }}</span>
                <span class="text-gray-700 text-xs block" v-if="note.assignee">
                  <span class="text-gray-600">{{note.note_type === 'Note' ? 'Recipient' : 'Assignee'}}: </span> {{ `${note.assignee.firstname} ${note.assignee.lastname}` }}</span>
                <span class="text-gray-700 text-xs block"><span class="text-gray-600">Created: </span> {{ note.created_at | momentLocal("MMMM DD, YYYY hh:mm A Z")  }}</span>
                <span class="text-gray-700 text-xs block" v-if="note.alert_date && note.note_type === 'Task'"><span class="text-gray-600">Assigned for: </span> {{ note.alert_date | momentLocal("MMMM DD, YYYY hh:mm A Z")  }}</span>
                <span class="text-gray-700 text-xs block" v-if="note.completed_at && note.note_type === 'Task'"><span class="text-gray-600">Completed on: </span> {{ note.completed_at | momentLocal("MMMM DD, YYYY hh:mm A Z")  }}</span>
                </div>
                <span class="text-gray-700 text-xs block mt-1" v-if="note.note_type === 'Task'">
                  <el-checkbox v-model="note.completed" :disabled="note?.assignee?.id !== $store.state.auth.user.id" @change="updateTask(note)" /> completed
                </span>
                <span class="text-gray-700 text-sm block mt-1">
                  <span class="inline-block mr-2" v-if="note.assignee && $store.state.auth.user.id === note.assignee.id">
                    <a href="#" @click="setReplyMessage(note.owner.id)" class="action">Reply</a>
                  </span>
                  <a href="#" class="action" @click="() => {new_note = Object.assign({}, note); show_create = true}" v-if="canUserUpdateOwnNote(note)">Edit</a>
                  <span v-if="$store.getters['auth/hasPermission']('note.update') && $store.getters['auth/hasPermission']('note.delete')">/</span>
                  <a href="#" class="action" @click="deleteNote(note)" v-if="$store.getters['auth/hasPermission']('note.delete')">Delete</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 flex flex-row justify-between items-center" v-if="note_pagination.total > note_pagination.rpp">
        <small>Showing page {{note_pagination.page}} of {{note_pagination.last_page}}. Total results {{note_pagination.total}}</small>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="note_pagination.total"
          :page-size.sync="note_pagination.rpp"
          :current-page="note_pagination.page"
          @current-change="notePaginationChange"
        >
        </el-pagination>
      </div>
    </trieste-card>

    <trieste-dialog :show.sync="show_create"
                    :title="(!new_note.id ? 'Create ' : 'Edit ') + new_note.note_type"
                    closeOnBackgroundClick>

      <div class="p-6 pb-2">
        <el-form :rules="new_note_rules" :model="new_note" label-width="120px" label-position="left" ref="noteForm" v-if="canUpdateOwnNote || canUpdateAllNote" v-loading="creating_note">
          <el-form-item label="Template" v-if="noteTemplates.length && canUpdateAllNoteFields">
            <el-select v-model="selected_note_template" @change="noteTemplateSelected" class="w-full" filterable>
              <el-option v-for="(nt, i) in noteTemplates" :key="i" :label="nt.name" :value="nt.body">{{nt.name}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="body" label="Body" v-if="canUpdateAllNoteFields">
            <el-input type="textarea" placeholder="Note..." v-model="new_note.body" />
          </el-form-item>

          <el-form-item prop="assigned_user_id" :label="new_note.note_type === 'task' ? 'Assigned User' : 'Recipient'"  v-if="canUpdateAllNoteFields" :required="new_note.note_type === 'task'">
            <el-select v-model="new_note.assigned_user_id" filterable class="w-full" :clearable="new_note.note_type !== 'task'">
              <el-option
                v-for="(cu, i) in company_users"
                :key="'cu_'+i"
                :value="cu.id"
                :label="`${cu.firstname} ${cu.lastname}`"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="type" label="type" v-if="canUpdateAllNoteFields">
            <el-select v-model="new_note.note_type">
              <el-option value="note" label="Note" />
              <el-option value="task" label="Task" />
              <el-option value="ART Note" label="Billing Note" />
            </el-select>
          </el-form-item>
          <el-form-item prop="alert_date" label="Alert date" v-if="new_note.note_type.toLowerCase() === 'task'">
            <el-date-picker v-model="new_note.alert_date" type="datetime" format="dd MMM yyyy hh:mm:ss" />
          </el-form-item>
          <el-form-item prop="urgent" label="urgent" v-if="canUpdateAllNoteFields">
            <el-switch v-model="new_note.urgent" />
          </el-form-item>
        </el-form>
        <div v-else>
          You are unable to update this note.
        </div>
      </div>
      <template v-slot:footer-right>
        <el-button type="danger" icon="el-icon-close" size="small" @click="show_create = false">Cancel</el-button>
        <el-button type="success" icon="el-icon-edit-outline" size="small" @click="createNote">{{!new_note.id ? 'Create' : 'Edit'}}</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>
<script>
import Note from "./inc/Note"
import Task from "./inc/Task"
import { urlInTextRegex } from '@/utils/helpers'
import moment from 'moment'

const defaultNote = {
    body: '',
    alert_date: null,
    assigned_user_id: null,
    note_type: 'note',
    is_urgent: false,
};
export default {
  name: 'NotesWidget',
  props: {
    resourceId: {
      type: [Number, String]
    },
    resourceType: {
      type: String,
      validator: (val) => ['ContentItem', 'LinkLead', 'LinkLeadsBatch', 'SiteLink'].includes(val)
    },
    defaultAssigneeId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    //defaultNote.assigned_user_id = this.$store.state.auth.user.id;
    const taskAssigneeValidator = (rule, value, callback) => {
      if(!this.new_note.assigned_user_id) {
        return callback(new Error('Please select an assignee'));
      }
      return callback()
    }
    return {
      company_users: [],
      notes: [],
      tasks: [],
      noteTemplates: [],
      notesAndTasks: [],
      loading: false,
      show_create: false,
      creating_note: false,
      selected_note_template: null,
      new_note: this.createDefaultNote(),
      note_pagination: {
        from: 1,
        last_page: 1,
        next_page_url: null,
        page: 1,
        prev_page_url: null,
        rpp: 20,
        to: 0,
        total: 0
      },
      new_note_rules: {
        body: [
          { required: true, min: 2, message: 'Please enter a note', trigger: 'blur' }
        ],
        assigned_task_user_id: [
          { validator: taskAssigneeValidator, min: 2, message: 'Please select an assignee', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    canSeeNotes() {
      return this.$store.getters['auth/hasPermission']('note.read')
    },
    canUpdateAllNote() {
      return this.new_note.id && this.$store.getters['auth/hasPermission']('note.update') || !this.new_note.id
    },
    canUpdateOwnNote() {
      return this.canUserUpdateOwnNote(this.new_note)
    } ,
    canUpdateAllNoteFields() {
      return this.canUserUpdateAllNoteFields(this.new_note)
    }
  },
  mounted() {
    console.log('mounted')
    this.getCompanyUsers()
    this.getNoteTemplates()
    this.getNotesAndTasks()
  },
  methods: {
    createDefaultNote() {
      return Object.assign({}, defaultNote, { assigned_user_id: this.defaultAssigneeId || null })
    },
    formatNote(note) {
      //return note.replace(/\n/g, '<br/>');
      return (note || '').replace(/\n/g, '<br/>').replace(urlInTextRegex, (a) => {
        return `<a href="${this.getOutboundSafeUrl(a)}" class="font-medium text-blue-700" target="_blank">${a}</a>`
      })
    },
    canUserUpdateOwnNote(note) {
      return this.canUserUpdateAllNoteFields(note) || note.id && note.note_type === 'Task' && note.user_id === this.$store.state.auth.user.id
    },
    canUserUpdateAllNoteFields(note) {
      return  !note.id || this.$store.getters['auth/hasPermission']('note.update') || note.user_id === this.$store.state.auth.user.id && moment(note.created_at).unix() > moment().subtract(2, 'minutes').unix()
    },
    getCompanyUsers() {
      this.$store.dispatch('users/loadAll', {})
      .then(r => {
        this.company_users = r;
      })
      .catch(() => {
        this.$message.error('The was an error loading the company users')
      })
    },
    noteTemplateSelected(content) {
      this.new_note.body = content;
      this.selected_note_template = null
    },
    setReplyMessage(owner_id) {
      this.openCreateNoteDialog()
      this.new_note.assigned_user_id = owner_id
    },
    getNoteTemplates() {
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/note-templates`).then((res) => {
        this.noteTemplates = res.data.data
      }).catch(() => {
        this.$message.error('Failed to get the note templates')
      })
    },
    getNotesAndTasks() {
      this.loading = true;
      this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/notes`, {
        params: {
          rpp: this.note_pagination.rpp,
          page: this.note_pagination.page,
          resource_id: this.resourceId,
          resource_type: this.resourceType
        }
      })
      .then(r => {
        const notes = [];
        const tasks = [];
        this.note_pagination  = r.data.pagination
        r.data.data.forEach(result => {
          if(!result.note_type || result.note_type.toLowerCase().trim() === 'note') {
            notes.push(result)
          } else {
            tasks.push(result)
          }
        })

        this.notes = notes;
        this.tasks = tasks;
        this.notesAndTasks = r.data.data.map(r => {
          if(r.body && r.body.length > 150) {
            r.show_full = false;
            r.preview = r.body.substr(0, 147) + '...';
          }
          return {
            ...r,
            isTask: r.note_type && r.note_type.toLowerCase().trim() === 'task'
          }
        })
      })
      .catch((e) => {
        this.$message.error('The was a problem loading the available notes and tasks.')
        console.error(e)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    notePaginationChange(e) {
      this.note_pagination.page = e;
      this.getNotesAndTasks();
    },
    openCreateNoteDialog() {
     // this.new_note = this.createDefaultNote();
      this.show_create = true;
    },
    deleteNote(note) {
      const type = note.note_type ? note.note_type.toLowerCase() : 'note'
      this.$confirm(`Are you sure you want to permanently delete this ${type}?`, 'Are you sure?', {
        confirmButtonClass: 'el-button--danger'
      })
      .then(() => {
        this.loading = true;
        const url = `/v1/companies/${this.$store.state.company.company.id}/notes/${note.id}`;
        this.$http.delete(url)
        .then(() => {
          this.$message.success('Successfully deleted the note')
          this.getNotesAndTasks()
        })
        .catch(() => {
          this.$message.error('Failed to delete the note. Please try again later.')
        })
      })
      .catch(() => {})
    },
    updateTask(note) {
      this.loading = true;
      let params = Object.assign({}, note)
      if(!this.canUpdateAllNote) {
        params = { alert_date: note.alert_date, _gmt_time: (new Date()).toUTCString() }
      }
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/notes/${note.id}`, note)
        .then(() => {
          this.$message.success('Successfully updated the task status')
        })
        .catch(() => {
          this.$message.error('Failed to update the status')
        })
        .finally(() => {
          this.loading = false;
        })
    },
    createNote() {
      this.$refs.noteForm.validate(valid => {
        if(valid) {
          const type = this.new_note.note_type.toLowerCase()
          const requestParams = Object.assign({}, this.new_note,
            {
            resource_type: this.resourceType,
            resource_id: this.resourceId,
            note_type: type === 'art note' ? 'ART Note' : (type === 'note' ? 'Note' : 'Task'),
            assigned_user_id: this.new_note.assigned_user_id || null,
            _gmt_time: (new Date()).toUTCString()
          })

          this.creating_note = true;
          let url = `/v1/companies/${this.$store.state.company.company.id}/notes`;
          let method = this.$http.post;

          if(this.new_note.id) {
            url += `/${this.new_note.id}`;
            method = this.$http.put;
          }
          method(url, requestParams)
          .then(() => {
            this.$message.success('Successfully created the note');
            this.new_note = this.createDefaultNote();
            this.getNotesAndTasks()
          })
          .catch(() => {
            this.$message.error('Failed to create the note. Please try again later.')
          })
          .finally(() => {
            this.creating_note = false;
            this.show_create = false;
          })
        }
      })
    }
  }
}
</script>
