var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"top",staticClass:"columns",attrs:{"id":"top"}},[_c('div',{staticClass:"column w-full xl:w-3/5"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('info',{attrs:{"active-same-domains":_vm.same_domain_active_links},on:{"contact-form-updated":_vm.setEmailFormContactUrl,"on-update":_vm.refreshEmailPreview},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1),_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('content-item',{model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1),(_vm.showPaymentsComponent && false)?_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('payment-note',{attrs:{"collapsed":_vm.paymentComponentCollapsed},on:{"update":_vm.update,"on-update":_vm.refreshEmailPreview},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1):_vm._e(),(_vm.showPaymentsComponent || true)?_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('payments',{attrs:{"collapsed":_vm.paymentComponentCollapsed},on:{"update":_vm.update,"on-update":_vm.refreshEmailPreview},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('contacts',{ref:"contactWidget",attrs:{"id":_vm.link.id,"include-links":"","exclude-domain":_vm.link.domain,"contacts":_vm.email_contacts,"collapsed":_vm.contactsCollapsed},on:{"update:contacts":function($event){_vm.email_contacts=$event},"loaded":_vm.contactsLoaded,"on-update":() => {
                    _vm.refreshEmailPreview();
                    _vm.refreshAutoFollowup();
                  }}}):_vm._e()],1),_c('div',{staticClass:"column w-full"},[(_vm.link.id)?_c('email-list',{ref:"emailList",attrs:{"id":_vm.link.id,"contacts":_vm.email_contacts,"show-send-email-button":false,"collapsed":_vm.emailListCollapsed,"owner-id":_vm.link.owner_id || _vm.link.created_by_id},on:{"send-email":_vm.setBlankNewEmail,"reply-to":_vm.setReplyEmail,"default-to-and-from":_vm.setDefaultToAndFrom,"block-email":_vm.blockEmail}}):_vm._e()],1),(_vm.link.id && _vm.show_email_form && _vm.link.auto_responder)?_c('div',{staticClass:"w-full"},[_c('auto-responder-settings',{attrs:{"link":_vm.link},on:{"append":({subject, body}) => {
            _vm.setAutoResponseEmailDetails(subject, body);
            _vm.focusEmail();
          },"refresh":() => {

          }},model:{value:(_vm.link.auto_responder),callback:function ($$v) {_vm.$set(_vm.link, "auto_responder", $$v)},expression:"link.auto_responder"}})],1):_vm._e(),(_vm.link.id && _vm.show_email_form)?_c('div',{ref:"emailFormHolder",staticClass:"column w-full"},[(_vm.link.id)?_c('email-form',{ref:"emailForm",attrs:{"value":_vm.link,"contacts":_vm.email_contacts,"last-email":_vm.lastReceivedEmail,"next-email-link":_vm.nextEmailLink,"default-to":_vm.defaultEmailTo,"default-from":_vm.defaultEmailFrom},on:{"success":_vm.refreshEmailList},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"column w-full xl:w-2/5"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column w-full xxl:w-2/5"},[(_vm.link.id)?_c('scores',{model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e(),(_vm.showLinkCheckWidget)?_c('div',{staticClass:"w-full"},[(_vm.link.id)?_c('check-link',{attrs:{"collapsed":_vm.linkCheckWidgetCollapsed},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"w-full"},[(_vm.link.id)?_c('heat-rating',{model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1)],1),_c('div',{staticClass:"column w-full xxl:w-3/5"},[_c('div',{staticClass:"w-full"},[(_vm.link.id)?_c('relationship',{attrs:{"id":_vm.link.id,"collapsed":_vm.relationshipWidthCollapsed},on:{"same-domains":(v) => this.same_domain_active_links = v},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):_vm._e()],1),(_vm.link.id)?_c('notes-widget',{attrs:{"resource-id":_vm.link.id,"default-assignee-id":_vm.link.owner_id,"resource-type":"SiteLink"}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }