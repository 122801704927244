<template>
  <div v-loading="loading">
    <trieste-card title="Domain Metrics">
      <template v-slot:header-right>

        <div>
          <el-dropdown size="mini" @command="handleClick">
            <el-button type="primary" size="mini">
               <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="refresh">Refresh</el-dropdown-item>
              <el-dropdown-item command="hardRefresh">Hard Refresh</el-dropdown-item>
              <el-dropdown-item command="history">View History</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div>

        </div>
      </template>
      <div class="p-4 text-xs">
        <div class="columns">
          <div class="column text-gray-600 flex-grow">
            Domain Rating
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('ahrefs_domain_rating', 'filter_ahrefs_domain_rating', 'filter_ahrefs_domain_rating')}">
            {{ value.ahrefs_domain_rating ? value.ahrefs_domain_rating : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            SEMRush Organic Traffic Score (US)
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('semrush_traffic_score', 'filter_semrush_traffic_score', 'filter_semrush_traffic_score')}">
            {{ value.semrush_traffic_score ? value.semrush_traffic_score.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            SEMRush Organic Traffic Score (UK)
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('semrush_traffic_score_uk', 'min_semrush_uk_traffic_score', 'min_semrush_uk_traffic_score')}">
            {{ value.semrush_traffic_score_uk ? value.semrush_traffic_score_uk.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            SEMRush Organic Traffic Score (Global)
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('semrush_traffic_score_global', 'min_semrush_global_traffic_score', 'min_semrush_global_traffic_score')}">
            {{ value.semrush_traffic_score_global ? value.semrush_traffic_score_global.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns">
          <div class="column text-gray-600 flex-grow">
            Ahrefs Monthly traffic
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('ahref_traffic', 'min_ahref_traffic_score', 'min_ahref_traffic_score')}">
            {{ value.ahref_traffic ? value.ahref_traffic.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns"  v-if="false">
          <div class="column text-gray-600 flex-grow">
            Host Ahrefs Monthly traffic
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('host_ahref_traffic', 'min_ahref_traffic_score', 'min_ahref_traffic_score')}">
            {{ value.host_ahref_traffic ? value.host_ahref_traffic.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns">
          <div class="column text-gray-600 flex-grow">
            Referring Domains
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('ref_domains', 'filter_rd', 'filter_rd')}">
            {{ value.ref_domains ? value.ref_domains.toLocaleString() : 0 }}
          </div>
        </div>
        <div v-if="false" class="columns">
          <div class="column text-gray-600 flex-grow">
            Trust Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('trust_flow', 'filter_tf', 'filter_tf')}">
            {{ value.trust_flow ? value.trust_flow : 0 }}
          </div>
        </div>
        <div v-if="false" class="columns">
          <div class="column text-gray-600 flex-grow">
            Citation Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('citation_flow', 'filter_cf')}">
            {{ value.citation_flow ? value.citation_flow : 0 }}
          </div>
        </div>
        <div v-if="false" class="columns">
          <div class="column text-gray-600 flex-grow">
            Trust Flow/Citation Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('tf_cf', 'filter_tf_cf')}">
            {{ value.tf_cf ? value.tf_cf.toFixed(2) : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            Nofollow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('nofollow_percent', 'filter_nofollow_percent', 'filter_nofollow_percent')}">
            {{ value.nofollow_percent ? value.nofollow_percent : 0 }}%
          </div>
        </div>
        <div v-if="false" class="columns">
          <div class="column text-gray-600 flex-grow">
            domain Authority
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('domain_authority', 'filter_da', 'min_domain_authority')}">
            {{ value.domain_authority ? value.domain_authority : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow" >
            SEMRush Keyword Volume Score
          </div>
          <div class="column text-gray-900">
            <el-popover
              v-if="semRushVolumeProof.length > 0"
              placement="right"
              width="400"
              popper-class="borderless--popper"
              trigger="hover">
              <div>
                <table>
                  <thead>
                  <tr>
                    <th>Keyword</th>
                    <th class="text-right w-24">Volume</th>
                    <th class="text-right w-24">Position</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    :key="i"
                    v-for="(v, i) in semRushVolumeProof">
                    <td>
                      {{ v.keyword }}
                    </td>
                    <td class="text-right">
                      {{ v.volume ? v.volume.toLocaleString() : 0 }}
                    </td>
                    <td class="text-right">
                      {{ v.position ? v.position.toLocaleString() : 0 }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <span slot="reference" class="border-b pb-1 cursor-pointer border-dashed border-gray-400"
                    :class="{'under': checkIfValueUnder('semrush_volume_score', 'filter_semrush_volume_score', 'filter_semrush_volume_score')}">
                        {{ value.semrush_volume_score ? value.semrush_volume_score.toLocaleString() : 0 }}
                    </span>
            </el-popover>
            <span v-if="!semRushVolumeProof.length" :class="{'under': checkIfValueUnder('semrush_volume_score', 'filter_semrush_volume_score', 'filter_semrush_volume_score')}">
                            {{ value.semrush_volume_score ? value.semrush_volume_score.toLocaleString() : 0 }}
                  </span>
          </div>
        </div>
        <div v-if="value.rld_updated_at" class="text-xs mt-4"><b>Refreshed On:</b> {{ value.rld_updated_at | momentLocal("MMMM DD, YYYY HH:mm Z") }}</div>
        <div v-if="compare_data.improved && compare_data.improved.length" class="text-xs mt-4"><b class="text-green-700">Improved Stats:</b> {{ compare_data.improved.map(i => i.replace(/_/g, ' ')).join(', ') }}</div>
        <div v-if="compare_data.declined && compare_data.declined.length" class="text-xs mt-4"><b class="text-red-600">Declined Stats:</b> {{ compare_data.declined.map(i => i.replace(/_/g, ' ')).join(', ') }}</div>
      </div>
    </trieste-card>


    <trieste-card v-if="false" title="Host Metrics">
      <div class="p-4 text-xs">
        <div class="columns">
          <div class="column text-gray-600 flex-grow">
            Referring Domains
          </div>
          <div class="column text-gray-900">
            {{ value.host_ref_domains ? value.host_ref_domains.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns">
          <div class="column text-gray-600 flex-grow">
            Trust Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('host_trust_flow', 'filter_host_tf', 'filter_host_tf')}">
            {{ value.host_trust_flow ? value.host_trust_flow.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            Citation Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('host_citation_flow', 'filter_host_cf', 'filter_host_cf')}">
            {{ value.host_citation_flow ? value.host_citation_flow.toLocaleString() : 0 }}
          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column text-gray-600 flex-grow">
            Trust Flow/Citation Flow
          </div>
          <div class="column text-gray-900" :class="{'under': checkIfValueUnder('host_tf_cf', 'filter_host_tf_cf', 'filter_host_tf_cf')}">
            {{ value.host_tf_cf ? parseFloat(value.host_tf_cf).toFixed(2) : 0 }}
          </div>
        </div>
        <div v-if="value.rld_updated_at" class="text-xs mt-4"><b>Refreshed On:</b> {{ value.rld_updated_at | momentLocal("MMMM DD, YYYY HH:mm Z") }}</div>
      </div>
    </trieste-card>
    <trieste-card title="Majestic TTF"  v-if="false">
      <template v-slot:header-right>
        <el-button
          @click="ttfExpanded = !ttfExpanded"
          :class="!ttfExpanded ? 'el-icon-arrow-down' : 'el-icon-arrow-up' "
          type="info"
          size="mini" />
      </template>
      <div class="p-4 text-xs">
        <div
          v-for="(metric, i) in ttf"
          :key="i"
          class="columns"
        >
          <div class="column">
            <div class="w-6 h-6 block flex justify-center items-center bg-green-500 text-white rounded-full">
              <span class="text-xxs font-bold">{{ metric.TopicalTrustFlow ? metric.TopicalTrustFlow : 0 }}</span>
            </div>
          </div>
          <div class="column flex items-center">
            <span class="w-64 truncate">{{ metric.Topic }}</span>
          </div>
        </div>
      </div>
    </trieste-card>
  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    props: ['value'],
    data() {
      return {
        ttfExpanded: false,
        loading: false,
        compare_data: {}
      }
    },
    computed: {
      ttf(){
        let items = this.value.majectic_topics ? this.value.majectic_topics : [];
        return this.ttfExpanded ? items : items.slice(0, 5);
      },
      semRushVolumeProof(){
        return this.value.semrush_volume_proof && Array.isArray(this.value.semrush_volume_proof) ? this.value.semrush_volume_proof : []
      },
    },
    mounted() {
      if(this.shouldAutoRefresh()) {
        this.refreshStats('hard_refresh')
      } else {
        this.compareStats();
      }
    },
    methods: {
      handleClick(command){
        if(command === 'refresh'){
          this.refreshStats();
        }
        if(command === 'hardRefresh'){
          this.refreshStats('hard_refresh')
        }
      },
      shouldAutoRefresh() {
        const statuses = [
          'negotiation',
          'negotiation_reminder_sent',
          'negotiation_waiting_for_reply',
          'awaiting_content',
          'content_available',
          'awaiting_fulfillment',
          'awaiting_placement',
          'new',
          'on_hold'
        ]

        if(
          statuses.includes(this.value.status) &&
          moment().diff(moment(this.value.created_at), 'months') <= 6 &&
          (!this.value.rld_updated_at || moment().diff(moment(this.value.rld_updated_at), 'days') >= 7)
        ) {
          return true
        }

        return false;
      },
      refreshStats(include) {
        this.loading = true
        const url = `/v1/companies/${this.$store.state.company.company.id}/links/${this.value.id}/refresh-stats`
        this.$http.get(url, { params: { include } })
          .then(r => {
            const stats = r.data
            const updatedLink = Object.assign({}, this.value, stats)
            this.link = updatedLink
            this.$emit('input', updatedLink)
            this.compareStats();
          })
          .catch(() => {
            this.$message.error('Failed to update the stats')
          })
          .finally(() => {
            this.loading = false
          })
      },
      compareStats() {
        this.loading = true
        const url = `/v1/companies/${this.$store.state.company.company.id}/links/${this.value.id}/compare-stats`
        this.$http.get(url, { params: { hostname: this.value.host } })
          .then(r => {
            this.compare_data = r.data
          })
          .catch(() => {
            this.$message.error('Failed to get state compare data')
          })
          .finally(() => {
            this.loading = false
          })
      },
      goToHistory() {
        this.$router.push(`/company/${this.$store.state.company.company.id}/leads/${this.$route.params.linkId}/metrics`)
      },
      checkIfValueUnder(linkKey, batchKey, siteKey) {
        const linkValue = this.value[linkKey]
        const batchValue = this.value.bulk_link_leads_batch_id && this.$store.state.batch.batch ? this.$store.state.batch.batch[batchKey] : null
        // const siteValue = this.value.site_id && this.$store.state.site.site ? this.$store.state.site.site[siteKey] : null

        if(
          (!batchValue /* && !siteValue*/) ||
          (batchValue && linkValue >= batchValue)
          // (siteValue && linkValue < siteValue)
        ) {
          return false;
        }

        return true;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .under {
    @apply font-medium text-red-600;
  }
</style>
