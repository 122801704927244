import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SiteLink from '../views/Companies/Sites/Campaigns/Link/View'
import Login from '../views/Login'
import store from '../store'
import CreateBatch from '../views/Companies/Sites/Campaigns/Batches/Create';
import EditBatch from '../views/Companies/Sites/Campaigns/Batches/Edit';
import BatchBulkEmailing from '../views/Companies/Sites/Campaigns/Batches/BatchBulkEmailing';
import Error404 from '../views/Errors/404';
import SiteTargetsIndex from '../views/Companies/Sites/Targets/Index';
import CompanyEmailAccounts from "../views/Companies/EmailAccounts/index";
import companyEmailTemplates from "../views/Companies/EmailTemplates/index";
import CompanyClients from "../views/Companies/Clients/Index";
import CompanyPayments from "../views/Companies/Payments/Index";
import CompanyLinkBuilding from "../views/Companies/LinkBuilding/Index";
import CompanyLinkBilling from "../views/Companies/LinkBuilding/Billing/Index";
import CompanyContent from "../views/Companies/Content/Index";
import ClientView from "../views/Companies/Clients/view";
import ViewSite from "../views/Companies/Sites/View";
import ViewSiteCampaign from "../views/Companies/Sites/Campaigns/View";
import CompanyBlackList  from "../views/Companies/Blacklist/Index";
import CompanyUserList from "../views/Companies/Users/Index";
import CompanyUserView from "../views/Companies/Users/View";
import TargetView from "../views/Companies/Sites/Targets/View";
import AllCompanyBatches from "../views/Companies/Batches/Index";
import CreateContent from "../views/Companies/Sites/Targets/Content/Edit"
import api from "./../utils/api";
import CompanyReports from "../views/Companies/Reports/Index";
import ForgottenPassword from "@/views/ForgottenPassword";
import ResetPassword from "@/views/ResetPassword";
import UserProfile from "@/views/User/Account";
import ViewEmailAccount from "../views/Companies/EmailAccounts/View";
import Admin from "../views/Admin/Admin";
import AdminIndex from "../views/Admin/Index";
import AdminCreate from "../views/Admin/Create";
import ProjectView from '../views/Companies/Clients/Projects/View';
import ProjectCreate from '../views/Companies/Clients/Projects/Create';
import LinkAllocationReport from "@/views/Companies/Reports/LinkAllocation/Index";
import CompanyLeads from "@/views/Companies/Lead/Index";
import NoteIndex from "@/views/Companies/Notes/Index";
import LeadMetricsHistory from "@/views/Companies/Sites/Campaigns/Link/Metrics/Index";
import NoteTemplates from "@/views/Companies/NoteTemplates/Index";
import SiteRequirements from "@/views/Companies/SiteRequirements";
import GoogleSignInReturn from "@/views/GoogleSignInReturn";
import LegacyGmailLoginRedirect from '@/views/Legacy/GmailLoginRedirect';
import LegacyGmailLoginReturn from '@/views/Legacy/GoogleReturn';
import LegacyMicrosoftLoginRedirect from '@/views/Legacy/MicrosoftLoginRedirect';
import LegacyMicrosoftLoginReturn from '@/views/Legacy/MicrosoftReturn';
import MicrosoftSignInReturn from "@/views/MicrosoftSignInReturn";
import PublisherHistoryIndex from "@/views/PublisherHistory/Index"


import ProjectTargets from "@/views/Companies/ProjectTargets/ProjectTargets"


import * as Sentry from "@sentry/vue";
import { captureConsoleIntegration } from "@sentry/integrations";

Vue.use(VueRouter)

const routes = [
// Fixed routes
  {
    path: '/google-sign-in',
    name: 'GoogleSignInReturn',
    component: GoogleSignInReturn,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      checkRedirect: true,
    }
  },
  {
    path: '/company/:companyId/publisher-history',
    name: 'PublisherHistoryIndex',
    component: PublisherHistoryIndex,
    meta: {
      requiresAuth: true,
      checkCompany: true,
    }
  },
  {
    path: '/company/:companyId/project-targets',
    name: 'ProjectTargetsIndex',
    component: ProjectTargets,
    meta: {
      requiresAuth: true,
      checkCompany: true,
    }
  },
  {
    path: '/modern-auth-sign-in',
    name: 'MicrosoftSignInReturn',
    component: MicrosoftSignInReturn,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      //checkRedirect: true,
    }
  },
  {
    path: '/legacy/gmail/login',
    name: 'LegacyGmailLoginRedirect',
    component: LegacyGmailLoginRedirect,
  },
  {
    path: '/legacy-google-sign-in',
    name: 'LegacyGmailLoginReturn',
    component: LegacyGmailLoginReturn
  },
  {
    path: '/legacy/microsoft/login',
    name: 'LegacyMicrosoftLoginRedirect',
    component: LegacyMicrosoftLoginRedirect,
  },
  {
    path: '/legacy-microsoft-sign-in',
    name: 'LegacyMicrosoftLoginReturn',
    component: LegacyMicrosoftLoginReturn
  },
  {
    path: '/company/:companyId/clients',
    name: 'CompanyClients',
    component: CompanyClients,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/clients/:clientId',
    name: 'ClientView',
    component: ClientView,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/sites/:siteId',
    name: 'ViewSite',
    component: ViewSite,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/sites/:siteId/batch/create',
    name: 'CreateBatch',
    component: CreateBatch,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/campaigns/:campaignId',
    name: 'ViewSiteCampaign',
    component: ViewSiteCampaign,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/campaigns/:campaignId/batch/create',
    name: 'CreateCampaignBatch',
    component: CreateBatch,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/batch/:batchId',
    name: 'EditBatch',
    component: EditBatch,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/batch/:batchId/bulk-emailing',
    name: 'BatchBulkEmailing',
    component: BatchBulkEmailing,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/batches',
    component: AllCompanyBatches,
    name: 'AllCompanyBatches',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/leads',
    component: CompanyLeads,
    name: 'AllCompanyLeads',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path:'/company/:companyId/content',
    component: CompanyContent,
    name: 'CompanyContents',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path:'/company/:companyId/notes',
    component: NoteIndex,
    name: 'CompanyNotes',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path:'/company/:companyId/note-templates',
    component: NoteTemplates,
    name: 'CompanyNoteTemplates',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/content/:contentId',
    name: 'EditContent',
    component: CreateContent,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true
    }
  },
  {
    path: '/company/:companyId/payments',
    name: 'companyPayments',
    component: CompanyPayments,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/site-targets/:targetId',
    name: 'SiteTargetsView',
    component: TargetView,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/links/:linkId',
    name: 'Link',
    component: SiteLink,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true,
    }
  },
  {
    path: '/company/:companyId/leads/:linkId',
    name: 'Leads',
    component: SiteLink,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true,
    }
  },
  {
    path: '/company/:companyId/leads/:linkId/metrics',
    name: 'LinkMetrics',
    component: LeadMetricsHistory,
    meta: {
      requiresAuth: true,
      checkCompany: true,
    }
  },
  {
    path: '/company/:companyId/email-accounts',
    name: 'CompanyEmailAccounts',
    component: CompanyEmailAccounts,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/email-accounts/:accountId',
    name: 'ViewEmailAccount',
    component: ViewEmailAccount,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/users',
    name: 'CompanyUserList',
    component: CompanyUserList,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/users/:userId',
    name: 'CompanyUserView',
    component: CompanyUserView,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/email-templates',
    name: 'companyEmailTemplates',
    component: companyEmailTemplates,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/blacklist',
    name: 'CompanyBlackList',
    component: CompanyBlackList,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/site-requirements',
    name: 'SiteRequirements',
    component: SiteRequirements,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/company/:companyId/clients/:clientId/projects/create',
    name: 'ProjectCreate',
    component: ProjectCreate,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true,
      noPaddingBottom: true
    }
  },
  {
    path: '/company/:companyId/projects/:projectId',
    name: 'ProjectView',
    component: ProjectView,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true,
      noPaddingBottom: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      forceSidebarCollapse: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: async () => await import('../views/Login.vue')
  },
  {
    path: '/tos',
    name: 'terms-of-service',
    component: async () => await import('../views/TermsOfService.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: async () => await import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/test',
    name: 'LoginTest',
    component: Login,
    meta: {
      checkRedirect: true
    }
  },
  {
    path: '/forgotten-password',
    name: 'ForgottenPassword',
    component: ForgottenPassword
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/account',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/company/:companyId/reports/link-allocation',
    component: LinkAllocationReport,
    meta: {
      requiresAuth: true
    }
  },
  {
      path:'/company/:companyId/content/attach/:linkId',
    component: CompanyContent,
    name: 'CompanyContentsAttach',
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':resource/index',
        component: AdminIndex,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ':resource/create',
        component: AdminCreate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ':resource/:resourceId',
        component: AdminCreate,
        meta: {
          requiresAuth: true
        }
      },
    ]
  },

// unnoone
  {
    path: '/company/:companyId/reports',
    name: 'CompanyReports',
    component: CompanyReports,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/link-building',
    name: 'CompanyLinkBuilding',
    component: CompanyLinkBuilding,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/link-building/billing',
    name: 'CompanyLinkBilling',
    component: CompanyLinkBilling,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/clients/:clientId/sites/:siteId/targets',
    name: 'SiteTargetsIndex',
    component: SiteTargetsIndex,
    meta: {
      requiresAuth: true,
      checkCompany: true
    }
  },
  {
    path: '/clients/:clientId/sites/:siteId/targets/:targetId/content/create',
    name: 'CreateContent',
    component: CreateContent,
    meta: {
      requiresAuth: true,
      checkCompany: true,
      forceSidebarCollapse: true
    }
  },
  {
    path: '/*',
    name: '404',
    component: Error404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


let firstLoad = true;

router.beforeEach((to, from, next) => {
  if((from.matched[0] || []).path !== (to.matched[0] || []).path) {
    store.commit('global/setBreadcrumbs', []); // always set the routes, its responsibility for the
  }

  if(to.matched.some(record => record.meta.checkRedirect)) {
    const url = new URL(window.location.href)
    if(url.origin !== process.env.VUE_APP_URL) {
      const rightURL = new URL(process.env.VUE_APP_URL)
      url.protocol = rightURL.protocol
      url.host = rightURL.host
      url.port = rightURL.port
      window.location = url.toString();
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.access_token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if(firstLoad) {
        // any events / triggers to be run on the first loaded page that requires an auth
        firstLoad = false;
      }

      // Check for new notifications on page each page load.... Dam
      store.dispatch('auth/getAlertCount', {
        alert_tasks: true
      })

      // We will check to see if we have a timer instance. If we do, push this to the server so we can store the user time.
      const timer = store.getters['auth/lastRecordedTimer'];
      store.dispatch('auth/resetTimer');
      if(timer) {
        api.post(`v1/auth/store-timer`, {
          timer
        })
        .then(() => {
          //
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          next()
        })
      } else {
        // Thank you- next
        next();
      }
    }
  } else {
    next();
  }
});

Sentry.init({
  Vue,
  dsn: "https://493ddd340b8e4e50a5a9af74f243f92f@o427247.ingest.us.sentry.io/5796851",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    captureConsoleIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.trieste\.io/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default router
