<template>
  <div v-loading="loading">
    <trieste-table
      default-sort-prop="email"
      item-type="email accounts"
      ref="table"
      :params="params"
      title="Email accounts" :end-point="'/v1/companies/' + $store.state.company.company.id + '/email-accounts'"
      :fields="fields"
      :filters="filters"
      :show-title="false"
      saved-table-sort-name="emailaccounts-index"
      :selected-rows.sync="selectedRow"
    >
      <template v-slot:header-right>
        <el-button type="success" size="medium" icon="el-icon-view" v-if="$store.getters['auth/hasPermission']('emailaccount.create')" @click="showCreateEmailAccount = true">Create</el-button>
        <el-button type="primary" size="medium" icon="el-icon-edit-outline" v-if="$store.getters['auth/hasPermission']('emailaccount.update') && false" @click="test()">
          {{ selectedRow.length ? 'Test selected' : 'Test All' }}
        </el-button>
      </template>
      <template v-slot:status-slot="props">
        <div class="block font-bold">{{ props.row.status[0].toUpperCase() + props.row.status.substring(1) }}</div>
        <template v-if="props.row.exception_string && false">
          <div class="block" v-if="props.row.status === 'processing' && props.row.num_exceptions < 10">
            retrying {{ props.row.num_exceptions }} / 10
          </div>
          <span class="text-xs text-red-600 font-medium">
            {{props.row.exception_string}}
          </span>
        </template>
      </template>

      <template v-slot:site-slot="props">
        <span v-if="props.row.all_sites">All</span>
        <el-tooltip :content="props.row.site.map(s => s.domain).join(', ')" placement="bottom-start" popper-class="email-site-popper" v-else>
          <span class="underline cursor-pointer">{{ props.row.site.length }} sites</span>
        </el-tooltip>
      </template>

      <template v-slot:actions-slot="props">
        <div class="flex gap-x-2 flex-row justify-end">
          <a
            :href="`/company/${$route.params.companyId}/email-accounts/${props.row.id}`"
            v-if="$store.getters['auth/hasPermission']('emailaccount.read')"
          >
            <el-button
              size="small"
              icon="el-icon-edit"
              type="primary">
              {{ $store.getters['auth/hasPermission']('emailaccount.update') ? 'Edit' : 'View' }}
            </el-button>
          </a>
          <div class="inline-block ml-2" v-if="props.row.is_gmail">
            <el-button size="small" :type="props.row.gmail_authenticated ? 'success' : 'danger'"
                       :icon="props.row.gmail_authenticated ? 'el-icon-check' : 'el-icon-close'"
                       @click="openGmailAuthenticationLink(props.row)">
              {{ props.row.gmail_authenticated ? 'Authenticated!' : 'Authenticate' }}
            </el-button>
          </div>
          <div class="inline-block ml-2" v-else-if="props.row.is_microsoft">
            <el-button size="small" :type="props.row.microsoft_authenticated ? 'success' : 'danger'"
                       :icon="props.row.microsoft_authenticated ? 'el-icon-check' : 'el-icon-close'"
                       @click="openMicrosoftAuthenticationLink(props.row)">
              {{ props.row.microsoft_authenticated ? 'Authenticated!' : 'Authenticate' }}
            </el-button>
          </div>
          <el-tooltip effect="dark" content="Currently testing the account" v-else-if="false"
                      placement="left-start" :disabled="!['TO_TEST', 'TESTING'].includes(props.row.tested)">
            <div class="inline-block ml-2">
              <el-button size="small" icon="el-icon-view"
                         v-if="canTestAccount(props.row)"
                         :disabled="['TO_TEST', 'TESTING'].includes(props.row.tested)"
                         :loading="['TO_TEST', 'TESTING'].includes(props.row.tested)"
                         @click="test(props.row.id)" type="primary">
                {{ ['TO_TEST', 'TESTING'].includes(props.row.tested) ? 'TESTING' : 'TEST' }}
              </el-button>
            </div>
          </el-tooltip>
        </div>
      </template>
    </trieste-table>
    <create-edit :show.sync="showCreateEmailAccount" @success="refresh" />
  </div>
</template>
<script>
import CreateEdit from "./../CreateEdit";
import CreateGoogleAuthenticationLink from '@/components/mixins/create_google_authentication_link'

export default {
  components: {CreateEdit},
  mixins: [ CreateGoogleAuthenticationLink ],
  data: function () {
    return {
      showCreateEmailAccount: false,
      query: {
        rpp: 20,
        page: 1
      },
      loading: false,
      selectedRow: [],
      params: {
        include_orphan_count: true
      },
      fields: [
        {
          sortField: 'first_name',
          name: 'first_name',
          title: 'First name'
        },
        {
          name: 'last_name',
          sortField: 'last_name',
          title: 'Last name',
        },
        {
          name: 'email',
          sortField: 'email',
          title: 'Email',
          width: '300px'
        },
        {
          name: 'last_checked',
          sortField: 'last_checked',
          title: 'Last synced',
          dateField: true
        },
        {
          title: 'Last Incoming',
          name: 'last_received_email',
          sortField: 'last_received_email',
          dateField: true
        },
        {
          title: 'Last Sent',
          name: 'last_sent_email',
          sortField: 'last_sent_email',
          dateField: true
        },
        {
          title: 'status',
          name: 'status-slot',
          sortField: 'status',
          width: '300px'
        },
        {
          title: 'Orphan emails',
          name: 'orphan_count'
        },
        {
          title: 'Sites',
          name: 'site-slot'
        },
        {
          title: '',
          name: 'actions-slot',
          fixed: 'right',
          width: '350px'
        },
      ],
      filters: [
        {
          label: 'First name',
          name: 'first_name'
        },
        {
          label: 'Last name',
          name: 'last_name'
        },
        {
          label: 'Email',
          name: 'email'
        },
        {
          label: 'Enabled',
          name: 'enabled',
          type: 'select',
          options: [
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]
        },
        {
          label: 'Active',
          name: 'is_active',
          type: 'select',
          options: [
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]
        },
        {
          label: 'Status',
          type: 'select',
          options: ['complete', 'processing', 'failed'],
          disableExclude: true
        },
        {
          label: 'Last synced',
          name: 'last_checked',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Last Sent Email',
          name: 'last_sent_email',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Last Incoming Email',
          name: 'last_received_email',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Owner',
          type: 'user',
          multiple: true,
          name: 'owner_id'
        },
        {
          label: 'Tested',
          type: 'select',
          options: ['tested', { value: 'to_test', label: 'to test' }, 'testing'],
          multiple: true
        }
      ]
    }
  },
  methods: {
    refresh() {
      this.$refs.table.getData()
    },
    test(ids) {
      if(ids) {
        ids = Array.isArray(ids) ? ids : [ids]
      } else if(this.selectedRow.length) {
        ids = this.selectedRow.map(r => r.id)
      }
      this.loading = true
      this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/email-accounts/test`, {
        ids,
        filter_params: this.$refs.table.getFilterData()
      }).then(() => {
        this.refresh()
      })
        .catch(() => {
          this.$message.error('Failed to test the email account(s)')
        })
        .finally(() => {
          this.loading = false
        })
    },
    openGmailAuthenticationLink(account) {
      let url = this.createGoogleAuthenticationLink(account.email, account.id, this.$store.state.company.company.id, account.client.client_id, '/google-sign-in');
    window.location.href = url;
    },
    openMicrosoftAuthenticationLink(account) {
      window.location.href = this.createMicrosoftAuthenticationLink(account.email, this.$store.state.company.company.id, account.id)
    },
    canTestAccount(account) {
      return (this.$store.getters['auth/hasPermission']('emailaccount.update') || account.owner_id === this.$store.state.auth.user.id)
    }
  }
}
</script>
<style lang="scss">
.email-site-popper {
  &.el-tooltip__popper {
    max-width: 80vw;
  }
}
</style>
