<template>
<div>
  <div class="font-semibold">
    Subject: {{ getSubject }}
  </div>
  <div class="bg-gray-100 break-normal	leading-5 p-2 text-sm" v-html="getContents">
  </div>
</div>
</template>

<script>

import emailPlaceholders from "@/json/email_placeholders.json";
import EmailPlaceholder from "@/utils/EmailPlaceholder";

export default {
  name: "PreviewTemplate",
  props: {
    subject: {
      type: String,
      default(){
        return ''
      }
    },
    content: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    getSubject(){
      let placeholderParser = new EmailPlaceholder(this.getMappings)
      let content = placeholderParser.parseIfStatments(placeholderParser.replaceLineBreaks(this.subject))
      return  placeholderParser.parse(content)
    },
    getContents(){
      let placeholderParser = new EmailPlaceholder(this.getMappings)
      let content = placeholderParser.parseIfStatments(placeholderParser.replaceLineBreaks(this.content))
      return  placeholderParser.parse(content).replaceAll('<br/>', '\n').replace(/\n\s*\n/g, '\n')
        .replace(/\n/g, "<br /><br />")
        //.replaceAll('<br/><br/>', '<br />').replaceAll('<br/> <br/>', '<br />')
    },
    getMappings(){
      let keys = emailPlaceholders.map((x) => x.code.replace('<<<', '').replace('>>>', ''));
      let values = emailPlaceholders.map((x) => x.default), object = {};
      for(let i = 0; i<= keys.length; i++){
        object[keys[i]] = values[i]
      }
      return object
    }
  },
  data(){
    return {}
  }
}
</script>

<style scoped>

</style>
